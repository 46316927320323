<template>
  <section class="survey">
    <van-nav-bar :title="title" left-arrow @click-left="handleQuit" />
    <van-skeleton :row="10" :loading="!questions.length">
      <div id="surveyContainer" class="survey-container">
        <div class="survey-header van-hairline--bottom">
          <van-cell :border="false">
            <template #title>
              <span :style="currentQuestion.style">
                <strong v-if="currentQuestion.isRequired" style="color: #f20202">*</strong>
                {{ currentQuestion.title }}
                <img
                  v-if="currentQuestion.imageLink"
                  :src="currentQuestion.imageLink"
                  @click="onImagePreview(currentQuestion.imageLink)"
                />
                <small
                  v-if="currentQuestion.type === 'checkbox' && currentQuestion.maxSelectedChoices > 0"
                  style="color: #f20202"
                >
                  ({{ localeText.mostOption }} {{ currentQuestion.maxSelectedChoices }})
                </small>
              </span>
            </template>
          </van-cell>
        </div>
        <div class="survey-content">
          <van-cell-group :border="false">
            <van-radio-group v-if="currentQuestion.type === 'radiogroup'" v-model="currentAnswer">
              <van-cell-group :border="false">
                <van-cell v-for="item in currentQuestion.children" :key="item.value" clickable>
                  <template #title>
                    <van-radio :name="item.value">
                      <span>{{ item.label }}</span>
                      <img
                        v-if="item.imageLink"
                        :src="item.imageLink"
                        @click="onImagePreview(item.imageLink)"
                      />
                    </van-radio>
                  </template>
                </van-cell>
                <van-cell v-if="currentQuestion.hasOther">
                  <van-radio name="other">{{ currentQuestion.otherText }}</van-radio>
                  <van-field
                    v-if="currentAnswer === 'other'"
                    v-model="currentAnswerOther"
                    clearable
                    maxlength="50"
                    show-word-limit
                    class="survey-other"
                  />
                </van-cell>
              </van-cell-group>
            </van-radio-group>
            <van-checkbox-group
              v-if="currentQuestion.type === 'checkbox'"
              v-model="currentAnswer"
              :max="currentQuestion.maxSelectedChoices"
            >
              <van-cell-group :border="false">
                <van-cell v-for="item in currentQuestion.children" :key="item.value" clickable>
                  <template #title>
                    <van-checkbox :name="item.value" shape="square">
                      <span>{{ item.label }}</span>
                      <img
                        v-if="item.imageLink"
                        :src="item.imageLink"
                        @click="onImagePreview(item.imageLink)"
                      />
                    </van-checkbox>
                  </template>
                </van-cell>
                <van-cell v-if="currentQuestion.hasOther">
                  <van-checkbox name="other" shape="square">
                    {{ currentQuestion.otherText }}
                  </van-checkbox>
                  <van-field
                    v-if="currentAnswer && currentAnswer.includes('other')"
                    v-model="currentAnswerOther"
                    clearable
                    maxlength="50"
                    show-word-limit
                    class="survey-other"
                  />
                </van-cell>
              </van-cell-group>
            </van-checkbox-group>
            <van-field
              v-if="currentQuestion.type === 'text'"
              v-model="currentAnswer"
              rows="5"
              clearable
              type="textarea"
              :maxlength="currentQuestion.maxLength"
              :placeholder="currentQuestion.placeHolder"
              show-word-limit
            />
            <div v-if="currentQuestion.type === 'rating'" class="survey-rate">
              <span class="survey-rate-start">{{ currentQuestion.minRateDescription }}</span>
              <van-rate
                v-model="currentAnswer"
                :count="currentQuestion.rateMax"
                :allow-half="currentQuestion.allowHalf"
                :size="32"
                color="#ffd21e"
                void-icon="star"
                void-color="#eee"
              />
              <span class="survey-rate-end">{{ currentQuestion.maxRateDescription }}</span>
            </div>
          </van-cell-group>
        </div>
        <div class="survey-footer van-hairline--top">
          <van-cell :border="false">
            <template v-if="showRequiredError">
              <div style="color: #f33">{{ currentQuestion.requiredErrorText }}</div>
            </template>
            <template #extra>
              <van-button v-if="nextIndex > 0" type="info" size="small" @click="handlePrev">
                {{ localeText.prev }}
              </van-button>
              <van-button
                type="info"
                size="small"
                @click="handleNext"
                :loading="nextLoading"
                :loading-text="localeText.loading"
              >
                {{ nextText }}
              </van-button>
            </template>
          </van-cell>
        </div>
      </div>
    </van-skeleton>
  </section>
</template>

<script>
  import { Dialog, ImagePreview } from 'vant';
  import { getParam, deepClone, unique } from '../utils/assists';
  import $http from '../utils/axios';
  import localeData from '../utils/locale';
  import { mockUser } from './mockData';

  export default {
    name: 'survey-content',
    data() {
      return {
        activityId: '',
        orderId: '',
        surveyId: '',
        userInfo: {},
        localeText: {},
        title: '',
        description: '',
        questions: [],
        questionsOrigin: [],
        nextIndex: 0,
        currentQuestion: {},
        currentAnswer: null,
        showRequiredError: false,
        currentAnswerOther: '',
        answers: [],
        nextLoading: false,
        nextText: '',
        submitted: false,
      };
    },
    methods: {
      handleQuit() {
        if (!this.questions.length) {
          window.doAppAction({ action: 'exitWebView' });
        } else {
          Dialog.confirm({
            title: this.localeText.unfinished,
            message: this.localeText.tips,
            cancelButtonText: this.localeText.cancel,
            confirmButtonText: this.localeText.confirm,
          })
            .then(() => {
              window.doAppAction({ action: 'exitWebView' });
            })
            .catch(() => {});
        }
      },
      onImagePreview(src) {
        ImagePreview({
          closeable: true,
          images: [src],
          showIndex: false,
        });
      },
      handlePrev() {
        document.getElementById('surveyContainer').scrollTop = 0;
        const { type, id } = this.currentQuestion;
        let data = {};
        if (type === 'rating' || type === 'text') {
          data = { content: '' };
        } else {
          data = { id: [], content: '' };
        }
        const answerIndex = this.answers.findIndex((item) => item.question_uid === id);
        this.$set(this.answers, answerIndex, Object.assign(this.answers[answerIndex], data));
        if (this.nextIndex <= 0) return;
        let childrenQuestions = [];
        const postParentQuestions = this.questions.slice(this.nextIndex + 1);
        const nextParent = postParentQuestions.find((item) => !item.parentId);
        if (nextParent) {
          // 下一个一级题目index
          const nextParentIndex = this.questions.findIndex((item) => item.id === nextParent.id);
          // 当前题目之后下一个一级题目之前的题目, 即当前题目的所有子题目
          childrenQuestions = this.questions.slice(this.nextIndex + 1, nextParentIndex);
        } else {
          // 当前题目之后的所有题目, 即当前题目的所有子题目
          childrenQuestions = this.questions.slice(this.nextIndex + 1);
        }
        if (childrenQuestions.length) {
          this.questions.splice(this.nextIndex + 1, childrenQuestions.length);
        }
        this.nextIndex -= 1;
        this.showRequiredError = false;
        this.currentQuestion = deepClone(this.questions[this.nextIndex]);
      },
      handleNext() {
        if (this.currentQuestion.isRequired) {
          if (this.currentQuestion.type === 'checkbox' && !this.currentAnswer.length) {
            this.showRequiredError = true;
            return;
          }
          if (!this.currentAnswer) {
            this.showRequiredError = true;
            return;
          }
        }
        this.showRequiredError = false;
        // 设置答案
        let data = {};
        const { type, id } = this.currentQuestion;
        let currentAnswerId = [];
        if (type === 'rating' || type === 'text') {
          data = { content: this.currentAnswer };
        } else {
          currentAnswerId = type === 'radiogroup' ? [this.currentAnswer] : this.currentAnswer;
          data = { id: currentAnswerId, content: this.currentAnswerOther };
        }
        const index = this.answers.findIndex((item) => item.question_uid === id);
        this.$set(this.answers, index, Object.assign(this.answers[index], data));
        // 下一题
        const questionCount = this.questions.length;
        this.nextIndex += 1;
        if (this.nextIndex <= questionCount - 1) {
          this.currentQuestion = deepClone(this.questions[this.nextIndex]);
        } else {
          this.handleSubmit();
          return;
        }
      },
      async handleSubmit() {
        this.nextLoading = true;
        try {
          const result = await $http.post('/sdk/paper_answer/up_answer', {
            paper_order_id: this.orderId,
            id: this.surveyId,
            answers: this.answers,
          });
          this.nextLoading = false;
          this.submitted = true;
          console.log(result);
          if (result) {
            sessionStorage.clear();
            this.$router.replace({ name: 'result', query: { code: 1 } });
          }
        } catch (error) {
          this.nextLoading = false;
        }
      },
    },
    beforeMount() {
      this.activityId = getParam('aid');
      this.orderId = getParam('oid');
      this.surveyId = getParam('id');
      window.getUser = async (data) => {
        console.info('request: ', data);
        if (data) {
          this.userInfo = data;
          const langId = data.language || '0';
          localStorage.setItem('lang_id', langId);
          this.localeText = localeData[langId];
          this.nextText = this.localeText['next'];
          const res = await $http.post(
            '/sdk/paper/info',
            {
              ...data,
              paper_uuid: this.surveyId,
              paper_order_id: this.orderId,
              activity_id: this.activityId,
            },
            { process: false }
          );
          if (res.code === 1) {
            this.title = res.data.title;
            this.description = res.data.description;
            this.questionsOrigin = res.data.questions;
            this.questions = this.questionsOrigin.filter((item) => !item.parentId);
            this.answers = this.questionsOrigin.map((item) => ({
              question_uid: item.id,
              type: item.type,
              id: [],
              content: '',
            }));
            this.currentQuestion = this.questions[this.nextIndex];
            switch (this.currentQuestion.type) {
              case 'checkbox':
                this.currentAnswer = [];
                break;
              case 'radiogroup':
                this.currentAnswer = '';
                break;
              case 'rating':
                this.currentAnswer = this.currentQuestion.rateMin;
                break;
              default:
                this.currentAnswer = '';
                break;
            }
            document.title = res.data.title;
          } else {
            sessionStorage.setItem('msg', res.message || this.localeText['lost']);
            sessionStorage.setItem('log_id', res.log_id || '');
            this.$router.replace({ name: 'result', query: { code: res.code } });
          }
        }
      };
    },
    mounted() {
      if (process.env.VUE_APP_BUILD_MODE === 'development' || getParam('debug') == 1) {
        window.getUser(mockUser);
      }
      window.doAppAction({ action: 'getToken', callback: 'getUser' });
      window.doAppAction({ action: 'handleBackPressed', callback: 'onBackPressed' });
      window.onBackPressed = () => {
        this.handleQuit();
      };
    },
    watch: {
      currentQuestion: {
        handler(data) {
          const { type, id } = data;
          const currentAnswerData = this.answers.find((item) => item.question_uid === id);
          switch (type) {
            case 'checkbox':
              this.currentAnswer = currentAnswerData?.id || [];
              this.currentAnswerOther = currentAnswerData?.content || '';
              break;
            case 'radiogroup':
              this.currentAnswer = currentAnswerData?.id[0] || '';
              this.currentAnswerOther = currentAnswerData?.content || '';
              break;
            case 'rating':
              this.currentAnswer = Number(currentAnswerData?.content) || 0;
              break;
            default:
              this.currentAnswer = currentAnswerData?.content || '';
              break;
          }
        },
        immediate: true,
      },
      currentAnswer(value) {
        const questionCount = this.questions.length;
        const { type, id, children } = this.currentQuestion;
        if (type === 'rating' || type === 'text') {
          if (this.nextIndex < questionCount - 1) {
            this.nextText = this.localeText['next'];
          } else {
            this.nextText = this.localeText['submit'];
          }
        } else {
          const currentAnswerId = type === 'radiogroup' ? [value] : value;
          if (Array.isArray(currentAnswerId)) {
            const currentChoices = children
              .filter((item) => currentAnswerId.includes(item.value))
              .reduce((a, b) => a.concat(b.childrenId), []);
            const nonChoices = children
              .filter((item) => !currentAnswerId.includes(item.value))
              .reduce((a, b) => a.concat(b.childrenId), []);
            if (nonChoices.length) {
              this.questions = this.questions.filter((item) => !nonChoices.includes(item.id));
            }
            const nextQuestions = this.questionsOrigin.filter((item) =>
              currentChoices.includes(item.id)
            );
            const currentIndex = this.questions.findIndex((item) => item.id === id);
            if (nextQuestions.length) {
              this.questions.splice(currentIndex + 1, 0, ...nextQuestions);
              this.nextText = this.localeText['next'];
            } else {
              if (this.nextIndex < questionCount - 1) {
                this.nextText = this.localeText['next'];
              } else {
                this.nextText = this.localeText['submit'];
              }
            }
          }
        }
        this.questions = unique(this.questions);
      },
    },
  };
</script>

<style>
  .survey {
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding-left: constant(safe-area-inset-left);
    padding-left: env(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);
    padding-right: env(safe-area-inset-right);
  }
  .van-nav-bar__content {
    height: 36px !important;
  }
  .van-skeleton__content {
    padding-top: 24px !important;
  }
  .survey-container {
    min-height: calc(100% - 36px);
  }
  .survey-header {
    min-height: 44px;
  }
  .survey-header .van-cell__title,
  .survey-content .van-checkbox__label,
  .survey-content .van-radio__label {
    display: flex;
    align-items: center;
  }
  .survey-header .van-cell__title small {
    padding-left: 8px;
    line-height: 1em;
    margin-top: 2px;
    vertical-align: middle;
  }
  .van-cell__title img {
    margin-left: 10px;
    height: 36px;
    vertical-align: middle;
  }
  .survey-content {
    min-height: calc(100% - 100px);
  }
  .survey-other {
    margin-top: 12px;
    border: 1px solid #ebedf0;
  }
  .survey-rate {
    padding: 60px 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .survey-rate-start {
    padding-right: 20px;
  }
  .survey-rate-end {
    padding-left: 20px;
  }
  .survey-footer .van-cell {
    height: 52px;
    justify-content: flex-end;
  }
  .survey-footer .van-cell .van-button--small {
    margin-left: 30px;
    min-width: 80px;
    width: auto !important;
    height: 36px;
  }
  .flex-wrap {
    display: flex;
    flex-wrap: wrap;
  }
  .flex-wrap .van-cell {
    width: 50%;
  }
</style>
