export const mockUser = {
  uid: '234544000081919',
  player_name: '还没名字',
  server_id: '12',
  user_vip_lv: '199',
  all_spent: '',
  '30_day_spent': '',
  current_level: '1',
  install_date: '1639398119729',
  platform: 'iOS',
  app_version: '1.0.2',
  device: 'iPhone 13',
  language: '1',
  country: 'CN',
  device_brand: 'Apple',
  os: '15.4.0',
  sdk_open_id: '892659866634558286',
  paper_order_id: '234544003334213',
  activity_id: null,
};
